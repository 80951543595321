<template>
  <div>
<!--    <el-button type="primary" icon="el-icon-plus" @click="addmember">新增成员</el-button>-->
    <el-link
       icon="el-icon-edit-outline"
       style="color: #3978e7; font-size: 16px; margin-right: 10px"
       :underline="false"
       @click="editDialog"
       >编辑
    </el-link>
    <div class="dialog_add">
      <el-dialog
        :visible.sync="dialogVisible"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">编辑成员</span>
        </div>
        <div class="body">
          <div class="body-left">
            <el-form label-width="150px">
              <el-form-item label="登录名:">
                <el-input v-model="editForm.LoginName"></el-input>
              </el-form-item>
              <el-form-item label="密码:">
                <el-input v-model="editForm.Password"></el-input>
              </el-form-item>
              <el-form-item label="姓名:">
                <el-input v-model="editForm.UserName"></el-input>
              </el-form-item>
              <el-form-item label="手机号:">
                <el-input v-model="editForm.PhoneNumber"></el-input>
              </el-form-item>
              <el-form-item label="身份证:">
                <el-input v-model="editForm.IdentityCard"></el-input>
              </el-form-item>
              <el-form-item label="性别:">
                <el-radio-group v-model="editForm.Sex">
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="0">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
          <div class="body-right">
            <el-form label-width="150px">
              <el-form-item label="微信:">
                <el-input v-model="editForm.WX"></el-input>
              </el-form-item>
              <el-form-item label="邮箱:">
                <el-input v-model="editForm.EMail"></el-input>
              </el-form-item>
              <el-form-item label="职称:">
                <el-select v-model="editForm.Title">
                  <el-option :label="item.Name" :value="item.Name" v-for="(item,index) in TitleArray" :key="index"></el-option>
                </el-select>
              </el-form-item>
<!--              <el-form-item label="医院:">-->
<!--                <el-input v-model="editForm.HospitalName" disabled></el-input>-->
<!--              </el-form-item>-->
              <el-form-item label="医院:" required>
                <el-autocomplete
                  class="inline-input"
                  v-model="editForm.HospitalName"
                  value-key="HospitalName"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="科室:" required v-if="!isShow">
                <el-input v-model="editForm.DeptName" disabled></el-input>
              </el-form-item>
              <el-form-item label="科室:" required v-if="isShow">
                <el-select v-model="editForm.DepartmentId" placeholder="请选择" filterable>
                  <el-option
                    :label="item.DeptName"
                    :value="item.Id"
                    v-for="(item, index) in Department"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="角色:">
                <el-select v-model="RoleId" placeholder="请选择">
                  <el-option
                    :label="item.RoleName"
                    :value="item.Id"
                    v-for="(item, index) in option"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="btn">
          <button  @click="dialogVisible = false">取消</button>
          <button @click="submitEdit">确定</button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from "@/api/url";
import research from "@/api/research";
import {getUsersInfo} from "@/api/project";

export default {
  name: "UserInfo",
  data() {
    return {
      PurchaseId: window.sessionStorage.getItem('PurchaseId'),
      bdId: "",
      saveRoleId: "",
      RoleId: "",
      dialogVisible: false, //编辑弹框
      option:[],//角色数组
      TitleArray:[],//职称数组
      HospitalId:"",//医院id
      DeptName: "", // 科室名称
      Department:[],//科室数组
      isShow: false, // 开始是否可以修改
      editForm: { // 编辑的信息
      },
    }
  },
  props: [ "Id"],
  created() {
  },
  methods: {
    editDialog() {
      this.dialogVisible = true
      this.titlelist()
      this.allrolelist()
      this.getUserInfo()
    },
    handleEditDialog() {
    },
    async getUserInfo() { // 获取用户信息
      const parame = { UserId: this.Id };
      const res = await getUsersInfo(parame);
      if (res.data.Status == 1) {
        if (res.data.Entity == null) {
          this.editForm = {}
          return false
        }
        // console.log(res.data.Entity,'userId')
        this.editForm = res.data.Entity;
        this.DeptName = res.data.Entity.DeptName
        if (res.data.Entity.RoleId !==null) {
          this.bdId = res.data.Entity.TopicRoleId
          this.RoleId = res.data.Entity.RoleId
          this.saveRoleId = res.data.Entity.RoleId
        } else {
          this.RoleId = ""
          this.saveRoleId = ""
          this.bdId = 0
        }
        // var obj = {
        //   TopicId: 0,
        //   UserId: res.data.Entity.UserId
        // }
        // research.listRole(obj).then(res => {
        //   // console.log(res,'编辑角色的对象')
        //   if (res.data.ExtraData !== null) {
        //     this.bdId = res.data.ExtraData.Id
        //     this.RoleId = res.data.ExtraData.RoleId
        //     this.saveRoleId = res.data.ExtraData.RoleId
        //   } else {
        //     this.RoleId = ""
        //     this.saveRoleId = ""
        //     this.bdId = 0
        //   }
        // })
      } else {
        this.$message.error(res.data.Message)
      }

    },
    allrolelist(){//角色
      api.allroleData().then(res=>{
        // console.log(res,'jues')
        if(res.data.Status==1){
          let arr = [];
          res.data.Entity.forEach((el) => {
            if (el.SysScope == 1) {
              arr.push(el);
            }
          });
          this.option= arr
        }else{
          this.$message.error(res.data.Message)
        }
      })
    },
    titlelist(){//职称数组
      // console.log('职称')
      let parame="JobTitle"
      api.titleData(parame).then(res=>{
        if(res.data.Status==1){
          this.TitleArray=res.data.Entity
        }else{
          alert(res.data.Message)
        }
      })
    },
    querySearch(queryString, cb){//查询出医院
      let parame={
        key:queryString
      }
      api.hospitalData(parame).then(res=>{
        if(res.data.Status==1){
          cb(res.data.Entity)
        }else{
          this.$message.error(res.data.Message)
        }
      })
    },
    handleSelect(item){//根据医院id查出科室
      this.editForm.HospitalId=item.Id
      let parame={
        HospitalId:item.Id
      }
      api.getDepartmentInfoData(parame).then(res=>{
        if(res.data.Status==1){
          this.Department=res.data.Entity
          this.isShow = true
        }else{
          this.$message.error(res.data.Message)
        }
      })
    },
    submitEdit() {
      research.personnelupData(this.editForm).then(res => {
        // console.log(res,'编辑成功')
        if (res.data.Status ==1) {
          if (this.RoleId != this.saveRoleId ) {
            let obj={
              Id: this.bdId,
              TopicId:0,
              RoleId:this.RoleId,
              UserId:this.editForm.Id,
            }
            research.updateRole(obj).then(res => {
              if (res.data.Status == 1) {
                // console.log(res,'编辑角色成功')
              } else {
                this.$message.error(res.data.Message)
              }
            })
          }
          this.$message.success(res.data.Message)
          this.dialogVisible = false
          // this.$emit('user-edit', true )
        } else {
          this.$message.error(res.data.Message)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/mixin.scss";
.dialog_add {
  /deep/ .el-dialog {
    width: 1200px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
    .body {
      height: 430px;
      .body-left {
        float: left;
        .el-form {
          .el-form-item {
            .el-form-item__label {
              @include add-size($font_size_16);
              color: #666666;
            }
            .el-input {
              @include add-size($font_size_16);
              width: 390px;
            }
          }
        }
      }
      .body-right {
        float: left;
        .el-form {
          .el-form-item {
            .el-form-item__label {
              @include add-size($font_size_16);
              color: #666666;
            }
            .el-input {
              @include add-size($font_size_16);
              width: 390px;
            }
            .el-icon-circle-plus-outline {
              width: 30px;
              display: inline-block;
              font-size: 30px;
              vertical-align: middle;
            }
            .pspan {
              display: inline-block;
              width: 360px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              vertical-align: middle;

              .spanname {
                @include add-size($font_size_16);
                margin-left: 6px;
              }
            }
            .block {
              .el-date-editor--daterange.el-input,
              .el-date-editor--daterange.el-input__inner,
              .el-date-editor--timerange.el-input,
              .el-date-editor--timerange.el-input__inner {
                width: 390px;
              }
            }
            .el-radio__label{
              @include add-size($font_size_16);
            }
          }
        }
      }
    }
    .btn {
      margin-bottom: 14px;
      text-align: center;
      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }
      button:nth-child(2) {
        width: 180px;
        border: 1px solid #3388ff;
        background: #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #fff;
      }
    }
  }
}
</style>

<template>
  <div class="SystemsettingPage">
    <div class="SystemsettingPage_box">
      <div class="content">
        <p>
          <span></span>
          <span>课题维护</span>
        </p>
        <div style="margin-bottom: 6px">
          <el-radio-group v-model="radio">
            <el-radio-button label="1">课题类型</el-radio-button>
            <el-radio-button label="2">课题方式</el-radio-button>
            <el-radio-button label="3">计划说明</el-radio-button>
          </el-radio-group>
          <div class="add-btn">
            <el-button v-show="radio == 1" @click="addtopicl" type="primary"
            >+新增课题类型</el-button
            >
            <el-button v-show="radio == 2" @click="addtopicf" type="primary"
            >+新增课题方式</el-button
            >
            <el-button v-show="radio == 3" @click="addtopicj" type="primary"
            >+新增计划说明</el-button
            >
          </div>
        </div>
        <div v-show="radio == 1" style="height: 200px;margin-bottom: 20px">
          <el-table
            border
            fit
            :data="tableDataType"
            style="width: 98%;margin-bottom: 20px"
            height="200"
            :header-cell-style="{
              color: '#333333',
              backgroundColor: '#E9E9E9',
            }"
          >
            <el-table-column
              prop="TypeName"
              align="center"
              label="课题类型"
              min-width="150"
            >
            </el-table-column>
            <el-table-column
              prop="TypeRemark"
              align="center"
              label="课题描述"
              min-width="205"
            >
            </el-table-column>
            <el-table-column label="操作" align="center" width="200px">
              <template slot-scope="scope">
                <el-link
                  icon="el-icon-edit-outline"
                  style="color: #3978e7; font-size: 16px; margin-right: 10px"
                  :underline="false"
                  @click="handleEdit(scope.$index, scope.row)"
                  >编辑</el-link
                >
                <el-link
                  icon="el-icon-delete"
                  style="color: #3978e7; font-size: 16px"
                  :underline="false"
                  @click="deltype(scope.$index, scope.row)"
                  >删除</el-link
                >
              </template>
            </el-table-column>
          </el-table>
          <pagination :total="total" :page.sync="listQuery.pageNum" :limit.sync="listQuery.pageSize" @pagination="getList"/>
        </div>
        <div v-show="radio == 2" style="height: 200px;margin-bottom: 20px">
          <el-table
            border
            fit
            highlight-current-row
            :data="tableDataMode"
            style="width: 98%;margin-bottom: 20px"
            height="200"
            :header-cell-style="{
              color: '#333333',
              backgroundColor: '#E9E9E9',
            }"
          >
            <el-table-column
              prop="Name"
              align="center"
              label="课题方式"
              min-width="400"
            >
            </el-table-column>
            <el-table-column label="操作" align="center" width="257px">
              <template slot-scope="scope">
                <el-link
                  icon="el-icon-edit-outline"
                  :underline="false"
                  style="color: #3978e7; font-size: 16px; margin-right: 10px"
                  @click="handleEdit1(scope.$index, scope.row)"
                  >编辑</el-link
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="sys-pagination">
            <pagination
              :total="total"
              :page.sync="listQuery.pageNum"
              :limit.sync="listQuery.pageSize"
              @pagination="getList"
            />
          </div>
        </div>
        <div v-show="radio == 3" style="height: 200px;margin-bottom: 20px">
          <el-table
            border
            fit
            highlight-current-row
            :data="tableDataPlan"
            style="width: 98%;margin-bottom: 20px"
            height="200"
            :header-cell-style="{
              color: '#333333',
              backgroundColor: '#E9E9E9',
            }"
          >
            <el-table-column
              prop="Name"
              align="center"
              label="计划说明"
              min-width="400"
            >
            </el-table-column>
            <el-table-column label="操作" align="center" width="257px">
              <template slot-scope="scope">
                <el-link
                  icon="el-icon-edit-outline"
                  style="color: #3978e7; font-size: 16px; margin-right: 10px"
                  :underline="false"
                  @click="handleEdit2(scope.$index, scope.row)"
                  >编辑</el-link
                >
              </template>
            </el-table-column>
          </el-table>
          <pagination
            :total="total"
            :page.sync="listQuery.pageNum"
            :limit.sync="listQuery.pageSize"
            @pagination="getList"
          />
        </div>
      </div>
      <div class="content">
        <p>
          <span></span>
          <span>菜单维护</span>
        </p>
        <div class="content-btn">
          <el-button type="primary" @click="addmenu">+新增菜单</el-button>
        </div>
        <div style="height: 200px;margin-bottom: 20px">
          <el-table
            border
            fit
            highlight-current-row
            :data="tableDataMenu"
            style="width: 98%;margin-bottom: 20px"
            max-height="200"
            :header-cell-style="{
              color: '#333333',
              backgroundColor: '#E9E9E9',
            }"
          >
            <el-table-column
              prop="MenuName"
              align="center"
              label="菜单名称"
              min-width="150"
            >
            </el-table-column>
            <el-table-column
              prop="MenuName"
              align="center"
              label="菜单说明"
              min-width="200"
            >
            </el-table-column>
            <el-table-column label="操作" align="center" width="200px">
              <template slot-scope="scope">
                <el-link
                  icon="el-icon-edit-outline"
                  style="color: #3978e7; font-size: 16px; margin-right: 10px"
                  :underline="false"
                  @click="handleMenuEdit(scope.$index, scope.row)"
                  >编辑</el-link
                >
                <el-link
                  icon="el-icon-delete"
                  style="color: #3978e7; font-size: 16px"
                  :underline="false"
                  @click="handleMenuDelete(scope.$index, scope.row)"
                  >删除</el-link
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <pagination
          :total="total"
          :page.sync="listQuery.pageNum"
          :limit.sync="listQuery.pageSize"
          @pagination="getList"
        />
      </div>
      <div class="content">
        <p>
          <span></span>
          <span>阶段维护</span>
        </p>
        <div class="content-btn">
          <el-button type="primary" @click="addbasicstagelist"
            >+新增基础阶段</el-button
          >
          <el-button type="primary" @click="exchange"
            ><i class="el-icon-sort"></i>阶段交换位置</el-button
          >
        </div>
        <div style="height: 200px;margin-bottom: 20px">
          <el-table
            border
            fit
            highlight-current-row
            :data="tableDataBasic"
            style="width: 98%;margin-bottom: 20px"
            max-height="200"
            :header-cell-style="{
              color: '#333333',
              backgroundColor: '#E9E9E9',
            }"
            ref="multipleTable"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column
              prop="FlowName"
              align="center"
              label="阶段名称"
              min-width="100px"
            >
            </el-table-column>
            <el-table-column
              prop="Remarks"
              align="center"
              label="阶段说明"
              min-width="205"
            >
            </el-table-column>
            <el-table-column label="操作" align="center" width="200px">
              <template slot-scope="scope">
                <el-link
                  icon="el-icon-edit-outline"
                  style="color: #3978e7; font-size: 16px; margin-right: 10px"
                  :underline="false"
                  @click="editBasic(scope.row)"
                  >编辑</el-link
                >
                <el-link
                  icon="el-icon-delete"
                  style="color: #3978e7; font-size: 16px"
                  :underline="false"
                  @click="basicDelete(scope.row)"
                  >删除</el-link
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <pagination
          :total="total"
          :page.sync="listQuery.pageNum"
          :limit.sync="listQuery.pageSize"
          @pagination="getList"
        />
      </div>
      <div class="content">
        <p>
          <span></span>
          <span>成员维护</span>
        </p>
        <div class="content-btn" style="display: flex">
<!--          <div style="margin-right: 10px">-->
<!--            <el-button type="primary" @click="addUser">+配置角色</el-button>-->
<!--          </div>-->
<!--          <el-button type="primary" @click="addUserDialog = true">+新增成员</el-button>-->
          <span>关键字:</span>
          <el-input v-model="UserListKey" placeholder="请输入姓名" style="width: 150px"></el-input>
          <el-button type="primary" @click="queryUser" style="width: 100px;height:40px;font-size:16px;margin-left: 10px">查询</el-button>
          <el-button type="primary" @click="restUser" style="width: 100px;height:40px;font-size:16px;margin-left: 10px;margin-right: 10px">重置</el-button>
          <div>
            <add-user @user-add="userAddList"></add-user>
          </div>
        </div>
        <div style="height: 200px;margin-bottom: 20px">
          <el-table
            border
            fit
            highlight-current-row
            :data="tableDataUser"
            style="width: 98%;"
            max-height="200"
            :header-cell-style="{
              color: '#333333',
              textAlign: 'center',
              backgroundColor: '#E9E9E9',
            }"
          >
            <el-table-column
              prop="UserName"
              align="center"
              label="姓名"
              min-width="150"
            >
            </el-table-column>
            <el-table-column
              prop="HospitalName"
              align="center"
              label="所属医院"
              min-width="250"
            >
            </el-table-column>
            <el-table-column
              prop="PhoneNumber"
              align="center"
              label="手机号"
              min-width="150"
            >
            </el-table-column>
            <el-table-column label="操作" width="160px">
              <template slot-scope="scope">
<!--                <el-link-->
<!--                  icon="el-icon-edit-outline"-->
<!--                  style="color: #3978e7; font-size: 16px; margin-right: 10px"-->
<!--                  :underline="false"-->
<!--                  @click="editUserInfo(scope.row)"-->
<!--                >编辑</el-link-->
<!--                >-->
                <div style="display: flex;justify-content: center">
                  <edit-user :Id="scope.row.Id" :key="scope.row.Id"></edit-user>
                  <el-link
                    icon="el-icon-delete"
                    style="color: #3978e7; font-size: 16px"
                    :underline="false"
                    @click="userRoleDel(scope.row)"
                  >删除</el-link
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <pagination
          :total="total6"
          :page.sync="queryUserList.PageIndex"
          :limit.sync="queryUserList.PageSize"
          @pagination=" userall"
        />
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible"  top="35vh" v-dialogDrag>
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">新增课题类型</span>
      </div>
      <el-form ref="form" label-width="90px">
        <el-form-item label="课题类型:">
          <el-input v-model="TypeName"></el-input>
        </el-form-item>
        <el-form-item label="课题描述:">
          <el-input v-model="TypeRemark" type="textarea" :rows="2"></el-input>
        </el-form-item>
        <div class="btn">
          <button  @click="oncancel">取消</button>
          <el-button @click="onSubmit" :loading="loadingType">确定</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible1" top="35vh" v-dialogDrag>
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">新增课题方式</span>
      </div>
      <el-form ref="form" label-width="90px">
        <el-form-item label="课题方式:">
          <el-input v-model="TopicMode"></el-input>
        </el-form-item>
        <div class="btn">
          <button  @click="oncancel1">取消</button>
          <el-button @click="onSubmit1" :loading="loadingMode">确定</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible2" v-dialogDrag>
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">新增计划说明</span>
      </div>
      <el-form ref="form" label-width="90px">
        <el-form-item label="计划说明:">
          <el-input v-model="TopicPlan"></el-input>
        </el-form-item>
        <div class="btn">
          <button @click="oncancel2">取消</button>
          <el-button @click="onSubmit2" :loading="loadingPlan">确定</el-button>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible3" top="35vh" v-dialogDrag>
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">课题类型编辑</span>
      </div>
      <el-form ref="form" label-width="90px">
        <el-form-item label="课题类型:">
          <el-input v-model="TypeName"></el-input>
        </el-form-item>
        <el-form-item label="课题描述:">
          <el-input v-model="TypeRemark"></el-input>
        </el-form-item>
        <div class="btn">
          <button @click="oncancel3">取消</button>
          <button @click="onSubmit3">确定修改</button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible4" top="35vh" v-dialogDrag>
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">课题方式编辑</span>
      </div>
      <el-form ref="form" label-width="90px">
        <el-form-item label="课题方式:">
          <el-input v-model="TopicMode"></el-input>
        </el-form-item>
        <div class="btn">
          <button @click="oncancel4">取消</button>
          <button @click="onSubmit4">确定修改</button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible5" top="35vh" v-dialogDrag>
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">计划说明编辑</span>
      </div>
      <el-form ref="form" label-width="90px">
        <el-form-item label="计划说明:">
          <el-input v-model="TopicPlan"></el-input>
        </el-form-item>
        <div class="btn">
          <button @click="oncancel5">取消</button>
          <button @click="onSubmit5">确定修改</button>
        </div>
      </el-form>
    </el-dialog>
    <!--      菜单维护对话框-->
    <el-dialog :visible.sync="dialogMenuVisible" top="35vh" v-dialogDrag>
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">新增菜单</span>
      </div>
      <el-form ref="form" label-width="80px">
        <el-form-item label="菜单名称">
          <el-input v-model="MenuName"></el-input>
        </el-form-item>
        <el-form-item label="菜单说明">
          <el-input v-model="Remarks"></el-input>
        </el-form-item>
        <div class="btn">
          <button @click="oncancel6">取消</button>
          <el-button @click="submitMenu" :loading="loadingMenu">确定</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="dialogMenuVisible1" top="35vh" v-dialogDrag>
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">编辑菜单</span>
      </div>
      <el-form ref="form" label-width="80px">
        <el-form-item label="菜单名称">
          <el-input v-model="MenuName"></el-input>
        </el-form-item>
        <el-form-item label="菜单说明">
          <el-input v-model="Remarks"></el-input>
        </el-form-item>
        <div class="btn">
          <button @click="oncancel7">取消</button>
          <button @click="submitMenu1">确定修改</button>
        </div>
      </el-form>
    </el-dialog>
    <!--      阶段维护-->
    <el-dialog
      :visible.sync="dialogBasicVisible" v-dialogDrag
      top="35vh"
    >
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">新增基础阶段</span>
      </div>
      <el-form ref="form" label-width="120px">
        <el-form-item label="基础阶段名称:">
          <el-input v-model="FlowName"></el-input>
        </el-form-item>
        <el-form-item label="基础阶段说明:">
          <el-input v-model="basicRemarks"></el-input>
        </el-form-item>
        <div class="btn">
          <button @click="oncancel8">取消</button>
          <el-button @click="basicSubmit" :loading="loadingBasic">确定</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogBasicVisible1"
      top="35vh"
      v-dialogDrag
    >
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">基础阶段编辑</span>
      </div>
      <el-form ref="form" label-width="120px">
        <el-form-item label="基础阶段名称:">
          <el-input v-model="FlowName"></el-input>
        </el-form-item>
        <el-form-item label="基础阶段说明:">
          <el-input v-model="basicRemarks"></el-input>
        </el-form-item>
        <div class="btn">
          <button @click="oncancel9">取消</button>
          <button @click="submitEditBasic">确定修改</button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogUserVisible1"
      v-dialogDrag
    >
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">配置角色</span>
      </div>
      <div style="display: flex;overflow: auto;height: 500px">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">姓名:</el-checkbox>
        <el-checkbox-group v-model="idlist" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="(data,index) in tableDataCheckAll" :label="data.Id" :key="index" >{{data.UserName}}</el-checkbox>
        </el-checkbox-group>
      </div>
      <el-form ref="form" label-width="120px">
        <el-form-item label="角色">
          <el-select v-model="RoleId" placeholder="请选择身份">
            <el-option :label="item.RoleName" :value="item.Id" v-for="(item,indexs) in list" :key="indexs"></el-option>
          </el-select>
        </el-form-item>
        <div class="btn">
          <button @click="oncancel10">取消</button>
          <el-button @click="submitEditUser" :loading="loadingUser">确定</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!--      删除课题类型对话框-->
    <div class="systemsetting">
      <el-dialog
        top="35vh"
        :visible.sync="delTypedialog"
        width="510px"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该课题类型，是否继续？</span>
        </div>
        <div class="btn">
          <button  @click="delTypedialog = false">取消</button>
          <button @click="delTopic">确定</button>
        </div>
      </el-dialog>
    </div>
    <!--      删除菜单对话框-->
    <div class="systemsetting">
      <el-dialog
        top="35vh"
        :visible.sync="delMenudialog"
        width="510px"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该菜单，是否继续？</span>
        </div>
        <div class="btn">
          <button  @click="delMenudialog = false">取消</button>
          <button @click="submitDelMenu">确定</button>
        </div>
      </el-dialog>
    </div>
<!--    删除阶段弹框-->
    <div class="systemsetting">
      <el-dialog
        top="35vh"
        :visible.sync="delBasicdialog"
        width="510px"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该阶段，是否继续？</span>
        </div>
        <div class="btn">
          <button  @click="delBasicdialog = false">取消</button>
          <button @click="submitDelbasic">确定</button>
        </div>
      </el-dialog>
    </div>
    <!--    删除成员弹框-->
    <div class="systemsetting">
      <el-dialog
        top="35vh"
        :visible.sync="delUserdialog"
        width="510px"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该成员，是否继续？</span>
        </div>
        <div class="btn">
          <button  @click="delUserdialog = false">取消</button>
          <button @click="submitDelUser">确定</button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import EditUser from "@/components/EditUser";
import AddUser from "@/components/AddUser";
import api from "../../../api/url.js";
import research from "../../../api/research.js";
import Pagination from "../../../components/Pagination/index";
import {delUser, getUser} from "@/api/project";
export default {
  components: { Pagination , AddUser, EditUser },
  data() {
    return {
      UserListKey: "",
      loadingUser: false, // 配置角色
      loadingBasic: false, // 阶段新增加载
      loadingMenu: false, // 菜单新增加载
      loadingPlan: false, // 计划说明新增加载
      loadingMode: false, // 课题方式新增加载
      loadingType: false, // 课题类型新增加载
      addUserDialog: false,
      queryUserList: { // 成员维护列表
        Key: "",
        TopicId: 0,
        PageIndex:1,
        PageSize:10,
      },
      total6: 0,
      total5: 0,
      total4: 0,
      total3: 0,
      total2: 0,
      total1: 0,
      delUserItem: {}, // 删除成员对象
      delUserdialog: false,// 删除成员弹框
      delBasicItem: {},//删除阶段对象
      delBasicdialog: false,//删除阶段弹框
      delMenuItem: {}, // 删除菜单对象
      delMenudialog: false, // 删除菜单弹框
      delTypedialog: false, //删除课题类型弹框
      delTypeItem: {}, //删除课题类型对象
      tableDataUser: [], //成员维护
      tableDataCheckAll:[],
      UserId: "",
      idlist:[],
      list: [],// 角色列表
      RoleId:"",
      dialogUserVisible1: false,
      checkAll: false,
      isIndeterminate: true,
      blist: [], // 阶段维护
      tableDataBasic: [],
      FlowName: "",
      basicRemarks: "",
      bid: "",
      FlowOrder: "",
      dialogBasicVisible: false,
      dialogBasicVisible1: false,
      // 菜单维护
      tableDataMenu: [],
      MenuName: "",
      Remarks: "",
      MenuOrder: "",
      dialogMenuVisible: false,
      dialogMenuVisible1: false,
      uid: "",
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 4,
      },
      radio: 1,
      tableDataType: [],
      tableDataMode: [],
      tableDataPlan: [],
      tableData: [
      ],
      TypeName: "",
      TypeRemark: "",
      TopicMode: "",
      TopicPlan: "",
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
    };
  },
  created() {
    this.base() //基础阶段
    this.topictype()
    this.topicmode()
    this.topicplan()
    this.menulistf()
    this.userall()
  },
  methods: {
    handleSelectionChange(list) {
      //选择
      this.blist = list;
    },
    typeDel(row) {
      console.log(row);
    },
    delTopic() { // 删除课题类型
      research.Deletetype({ Id: this.delTypeItem.Id }).then((res) => {
      if (res.data.Status == 1) {
        this.$message.success('删除成功')
        this.topictype();
        this.delTypedialog = false
      } else {
        this.$message.error(res.data.Message);
      }
      })
    },
    deltype(i, item) {
      this.delTypeItem = item
      this.delTypedialog = true //删除课题类型
    },
    topictype() {
      research.xiangmu().then((res) => {
        //获取课题类型列表
        if (res.data.Status == 1) {
          this.tableDataType = res.data.Entity;
          // this.total1 = res.data.Entity.length
          }else {
            this.$message.error(res.data.Message)
          }
      });
    },
    topicmode() {
      research.Subjecttype("TopicMode").then((res) => {
        //获取课题方式列表
        if (res.data.Status == 1) {
          this.tableDataMode = res.data.Entity;
          // this.total2 = res.data.Entity.length
          // console.log(res, "获取课题方式列表");
          }else {
            this.$message.error(res.data.Message)
          }
      });
    },
    topicplan() {
      research.Subjecttype("TopicPlan").then((res) => {
        //获取课题计划说明列表
        if (res.data.Status == 1) {
          this.tableDataPlan = res.data.Entity;
          // this.total3 = res.data.Entity.length
          // console.log(res, "获取课题计划说明列表");
        }else {
          this.$message.error(res.data.Message)
        }
      });
    },
    addtopicl() {
      this.dialogVisible = true;
    },
    onSubmit() {
      //确定新增课题类型列表
      var addobjl = {
        TypeRemark: this.TypeRemark,
        TypeName: this.TypeName,
      };
      this.loadingType = true
      research.addtype(addobjl).then((res) => {
        if (res.data.Status == 1) {
          this.$message.success(res.data.Message);
          this.TypeName = "";
          this.TypeRemark = "";
          this.dialogVisible = false;
          this.loadingType = false;
          this.topictype();
        }else {
          this.$message.error(res.data.Message)
          this.loadingType = false
        }
      }).catch(() => {
        this.loadingType = false
      });
    },
    addtopicf() {
      this.dialogVisible1 = true;
    },
    handleEdit(i, item) {
      //打开修改课题类型弹框
      console.log(item);
      this.dialogVisible3 = true;
      this.TypeName = item.TypeName;
      this.TypeRemark = item.TypeRemark;
      this.Id = item.Id;
    },
    onSubmit3() {
      //确定修改课题类型
      var upboj = {
        TypeName: this.TypeName,
        TypeRemark: this.TypeRemark,
        Id: this.Id,
      };
      research.updatetype(upboj).then((res) => {
        if (res.data.Status == 1) {
          this.$message.success("修改成功");
          this.Id = "";
          (this.TypeName = ""), (this.TypeRemark = "");
          this.dialogVisible3 = false;
          this.topictype();
        } else {
          this.$message.error(res.data.Message)
        }
        console.log(res);
      });
    },
    onSubmit1() {
      //确定新增课题方式列表
      if (this.TopicMode == ""){
        this.$message.error('课题方式不能为空')
        return false
      }
      var addobjf = {
        type: "TopicMode",
        Name: this.TopicMode,
        Code: "0",
      };
      this.loadingMode = true
      research.addtopic(addobjf).then((res) => {
        if (res.data.Status == 1) {
          this.$message.success(res.data.Message);
          this.TopicMode = "";
          this.dialogVisible1 = false;
          this.loadingMode = false
          this.topicmode();
        }else {
          this.$message.error(res.data.Message)
          this.loadingMode = false
        }
      }).catch(() => {
        this.loadingMode = false
      });
    },
    handleEdit1(i, item) {
      //打开修改课题方式列表弹框
      this.TopicMode = item.Name;
      this.Id = item.Id;
      this.dialogVisible4 = true;
    },
    onSubmit4() {
      var upboj = {
        type: "TopicMode",
        Name: this.TopicMode,
        Code: "0",
        Id: this.Id,
      };
      research.updatetopic(upboj).then((res) => {
        if (res.data.Status == 1) {
          this.$message.success(res.data.Message);
          this.Id = "";
          this.TopicMode = "";
          this.dialogVisible4 = false;
          this.topicmode();
        }else {
          this.$message.error(res.data.Message)
        }
        console.log(res);
      });
    },
    addtopicj() {
      this.dialogVisible2 = true;
    },
    handleEdit2(i, item) {//打开修改课题计划说明列表弹框
      this.TopicPlan = item.Name;
      this.Id = item.Id;
      this.dialogVisible5 = true;
    },
    onSubmit5() {
      var upboj = {
        type: "TopicPlan",
        Name: this.TopicPlan,
        Code: "0",
        Id: this.Id,
      };
      research.updatetopic(upboj).then((res) => {
        if (res.data.Status == 1) {
          this.$message.success(res.data.Message);
          this.Id = "";
          this.TopicPlan = "";
          this.dialogVisible5 = false;
          this.topicplan();
        }else {
          this.$message.error(res.data.Message)
        }
        console.log(res);
      });
    },
    onSubmit2() {//确定新增课题计划说明列表
      if (this.TopicPlan == ""){
        this.$message.error('计划说明不能为空')
        return false
      }
      var addobjj = {
        type: "TopicPlan",
        Name: this.TopicPlan,
        Code: "0",
      };
      this.loadingPlan = true
      research.addtopic(addobjj).then((res) => {
        if (res.data.Status == 1) {
          this.$message.success(res.data.Message);
          this.TopicPlan = "";
          this.dialogVisible2 = false;
          this.loadingPlan = false
          this.topicplan();
        }else {
          this.$message.error(res.data.Message)
          this.loadingPlan = false
        }
      }).catch(() => {
        this.loadingPlan = false
      });
    },
    menulistf(){ //菜单列表
      research.menulists(0).then(res=>{
        if (res.data.Status == 1) {
          this.tableDataMenu =res.data.ExtraData
          // this.total4 = res.data.ExtraData.length
          this.tableDataMenu.forEach(item=>{
            this.MenuOrder=item.MenuOrder
          })
        }else {
          this.$message.error(res.data.Message)
        }
        // console.log(res,"讨论区菜单");
      })
    },
    addmenu() {//打开新增菜单弹框
      this.dialogMenuVisible = true;
    },
    submitMenu() {//确认新增菜单
      var menuobj = {
        MenuName: this.MenuName,
        Remarks: this.Remarks,
        MenuType: 0,
        MenuOrder: this.MenuOrder + 1,
      };
      this.loadingMenu = true;
      research.addmenu(menuobj).then((res) => {
        if (res.data.Status == 1) {
          this.$message.success("新增成功");
          this.MenuName = "";
          this.Remarks = "";
          this.dialogMenuVisible = false;
          this.loadingMenu = false;
          this.menulistf()
        } else {
          this.$message.error(res.data.Message)
        }
      }).catch(() => {
        this.loadingMenu = false
      });
    },
    submitDelMenu() { // 提交删除菜单
      research.Deletemenu({ Id: this.delMenuItem.Id }).then((res) => {
      if (res.data.Status == 1) {
        this.$message.success("菜单删除成功");
        this.menulistf()
        this.delMenudialog = false
      } else {
        this.$message.error(res.data.Message)
      }
    });
    },
    handleMenuDelete(i, item) {//删除菜单弹框
      this.delMenuItem = item
      this.delMenudialog = true
    },
    handleMenuEdit(i, item) {//打开修改菜单弹框
      this.uid = item.Id;
      this.MenuName = item.MenuName;
      this.Remarks = item.Remarks;
      this.dialogMenuVisible1 = true;
    },
    submitMenu1() {//确定修改菜单
      var upobj = {
        Id: this.uid,
        MenuName: this.MenuName,
        Remarks: this.Remarks,
      };
      research.Updatemenu(upobj).then((res) => {
        if (res.data.Status == 1) {
          this.$message.success("修改成功");
          this.MenuName = "";
          this.Remarks = "";
          this.dialogMenuVisible1 = false;
          this.menulistf()
          console.log(res);
        } else {
          this.$message.error(res.data.Message)
        }
      });
    },
    addbasicstagelist() {//打开新增基础阶段弹框
      this.dialogBasicVisible = true;
    },
    editBasic(item) { // 打开编辑基础阶段弹框
      this.FlowOrder=item.FlowOrder
      this.FlowName=item.FlowName
      this.basicRemarks=item.Remarks
      this.bid=item.Id
      this.dialogBasicVisible1 = true;
    },
    submitEditBasic() { // 提交编辑阶段
      var bobj={
        Id:this.bid,
        FlowName:this.FlowName,
        Remarks:this.basicRemarks,
        FlowOrder:this.FlowOrder,
      }
      research.Updatebasicstagelist(bobj).then(res=>{
        if(res.data.Status==1){
          this.$message.success("修改成功")
          this.FlowName=""
          this.basicRemarks=""
          this.bid=""
          this.dialogBasicVisible1=false
          this.base()
        }else {
          this.$message.error(res.data.Message)
        }
        console.log(res);
      })
    },
    submitDelbasic() { // 提交基础阶段删除
      research.Deletebasicstagelist({Id:this.delBasicItem.Id}).then(res=>{
      if(res.data.Status==1){
        this.$message.success("基础阶段删除成功")
        this.base()
        this.delBasicdialog = false
      } else {
        this.$message.error(res.data.Message)
      }
    })
    },
    basicDelete(item){//打开基础阶段删除弹框
      this.delBasicItem = item
      this.delBasicdialog = true
    },
    exchange() {
      //交换位置
      if (this.blist.length == 2) {
        console.log(this.blist);
        var abcobj = {
          Id1: this.blist[0].Id,
          Id2: this.blist[1].Id,
        };
        research.exchangebasicstagelist(abcobj).then((res) => {
          if (res.data.Status == 1) {
           this.$message.success("交换成功");
            this.base();
          }
          console.log(res);
        });
      } else {
        this.$message.warning("只能两个基础位置交换");
      }
    },
    basicSubmit() {//确定新增基础阶段
      var addobj = {
        FlowName: this.FlowName,
        Remarks: this.basicRemarks,
      };
      this.loadingBasic = true
      research.addbasicstagelist(addobj).then((res) => {
        if (res.data.Status == 1) {
          this.$message.success("基础阶段新增成功");
          this.FlowName = "";
          this.basicRemarks = "";
          this.dialogBasicVisible = false;
          this.loadingBasic = false
          this.base();
        }else {
          this.$message.error(res.data.Message)
          this.loadingBasic = false
        }
      }).catch(() => {
        this.loadingBasic = false
      });
    },
    base() {//基础阶段
      research.basicstagelist().then((res) => {
        if (res.data.Status == 1) {
        this.tableDataBasic = res.data.Entity;
        // this.total5 = res.data.Entity.length
        }else {
          this.$message.error(res.data.Message)
        }
      });
    },
    queryUser() {
      this.userall()
    },
    restUser() {
      this.UserListKey = ""
      this.userall()
    },
    async userall(){ // 成员维护
      var queryInfo= {
          key:this.UserListKey,
          PageIndex: this.queryUserList.PageIndex-1,
          PageSize: 10,
      };
      const res = await getUser(queryInfo);
      console.log(res);
      if (res.data.Status !== 1)
        return this.$message.error("获取用户列表失败！");
      this.tableDataUser = res.data.Entity;
      this.total6 = res.data.TotalRowsCount;
      // var obj = {
      //   Key: "",
      //   TopicId: 0,
      //   PageIndex:this.queryUserList.PageIndex-1,
      //   PageSize:5,
      // }
      // console.log(this.queryUserList.PageIndex,'页数')
      // research.getUserSList(obj).then(res=>{
      //   console.log(res);
      //   if(res.data.Status==1){
      //     this.tableDataUser=res.data.Entity
      //     this.total6 = res.data.TotalRowsCount
      //   }else{
      //     alert(res.data.Message)
      //   }
      // })
    },
    userAddList(data) {
        if (data) {
          this.userall()
        }
    },
    addUser() { // 打开配置角色弹框
      this.idlist = []
      this.dialogUserVisible1 = true
      research.allrole().then(res=>{
        this.list=res.data.Entity
        console.log(res);
      })
      let useid = window.sessionStorage.getItem("Id")
      research.allmember(useid).then(res=>{
        this.tableDataCheckAll = res.data.Entity
        console.log(res);
      })
    },
    handleCheckAllChange(val) {
      var list = JSON.parse(JSON.stringify(this.tableDataCheckAll))
      list = list.map(item=>{
        return item.Id
      })
      this.idlist = val ? list : [];
      console.log(this.idlist)
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.tableDataCheckAll.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.tableDataCheckAll.length;
      console.log(value)
      console.log(this.idlist)
    },
    submitEditUser() { // 确认角色
      this.idlist.forEach((item,index) => {
      // this.UserId = this.idlist[0]
      var useobj={
        RoleId:this.RoleId,
        // UserId: this.UserId
        UserId: item
      }
      this.loadingUser = true
      research.adduser(useobj).then(res=>{
        if(res.data.Status==1){
          this.UserId= ""
          this.RoleId=""
          if (this.idlist.length-1 == index) {
            this.$message.success(res.data.Message)
            this.userall()
          }
          this.loadingUser = false
          this.dialogUserVisible1=false
        } else {
          this.$message.error(res.data.Message)
          this.loadingUser = false
        }
        console.log(res);
      })
      })
    },
    submitDelUser() { // 提交成员删除
      delUser([this.delUserItem.Id]).then(res=>{
          if(res.data.Status==1){
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.delUserdialog = false
            this.userall()
          } else {
            this.$message.error(res.data.Message)
          }
        })
    },
    userRoleDel(item) {
      this.delUserItem = item
      this.delUserdialog = true
    },
    oncancel() {
      (this.TypeName = ""), (this.TypeRemark = "");
      this.dialogVisible = false;
    },
    oncancel1() {
      this.TopicMode = "";
      this.dialogVisible1 = false;
    },
    oncancel2() {
      this.TopicPlan = "";
      this.dialogVisible2 = false;
    },
    oncancel3() {
      //关闭修改课题类型弹框
      this.Id = "";
      (this.TypeName = ""), (this.TypeRemark = "");
      this.dialogVisible3 = false;
    },
    oncancel4() {
      //关闭修改课题方式列表弹框
      this.Id = "";
      this.TopicMode = "";
      this.dialogVisible4 = false;
    },
    oncancel5() {
      //关闭修改课题计划说明列表
      this.Id = "";
      this.TopicPlan = "";
      this.dialogVisible5 = false;
    },
    oncancel6() {
      this.MenuName = "";
      this.Remarks = "";
      this.dialogMenuVisible = false;
    },
    oncancel7() {
      //关闭修改弹窗
      this.MenuName = "";
      this.Remarks = "";
      this.dialogMenuVisible1 = false;
    },
    oncancel8() {
      this.FlowName = "";
      this.basicRemarks = "";
      this.dialogBasicVisible = false;
    },
    oncancel9() {
      //关闭基础阶段修改弹框
      this.FlowName = "";
      this.basicRemarks = "";
      this.bid = "";
      this.dialogBasicVisible1 = false;
    },
    oncancel10() { // 关闭配置角色弹框
      this.idlist= []
      this.RoleId=""
      this.UserId = ""
      this.dialogUserVisible1 = false
    },
    getList() {},
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/mixin.scss";
.SystemsettingPage {
  width: 100%;
  //height: 1080px;
  height:100%;
  // height: 840px;
  overflow: hidden;
  .SystemsettingPage_box {
    display: flex;
    flex-flow: row wrap;
    height: 96%;
    overflow: hidden;
    .content {
      //width: 800px;
      width: 47%;
      height: 410px;
      //overflow-y: scroll;
      background: #ffffff;
      border-radius: 10px;
      position: relative;
      margin-left: 15px;
      margin-top: 18px;
      padding-left: 22px;
      p {
        overflow: hidden;
        margin-top: 14px;
        margin-bottom: 14px;
        span:nth-child(1) {
          display: inline-block;
          width: 3px;
          height: 16px;
          background: #3388ff;
          margin-right: 10px;
          vertical-align: middle;
        }
        span:nth-child(2) {
          display: inline-block;
          color: #333333;
          @include add-size1($font_size_20);
          vertical-align: middle;
        }
      }
      .content-btn {
        height: 30px;
        margin-bottom: 16px;
        span {
          display: inline-block;
          margin: 10px 5px;
          color: #666666;
          @include add-size($font_size_16);
        }
        .el-input {
          /deep/.el-input__inner {
            @include add-size($font_size_16);
            height: 40px;
          }
        }
      }
      .add-btn {
        position: absolute;
        top: 56px;
        right: 20px;
      }
    }
  }
}
/deep/.el-dialog {
  width:510px;
  border-radius: 10px;
  .el-dialog__header {
    border-radius: 10px 10px 0px 0px;
    background: #3388ff;
    .header-title {
      .title-name {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 4px solid #ffffff;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 4px;
        margin-bottom: 5px;
      }
      .title-age {
        vertical-align: top;
        display: inline-block;
        @include add-size($font_size_16);
        color: #ffffff;
      }
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }
  }
  .el-form {
    .el-form-item {
      .el-form-item__label {
        @include add-size($font_size_16);
        color: #666666;
      }
      .el-input__inner {
      }
      .el-input {
        @include add-size($font_size_16);
        margin-right: 10px;
      }
      .el-textarea__inner {
        @include add-size($font_size_16);
      }
    }
  }
  .el-checkbox {
    @include add-size($font_size_16);
  }
  .el-checkbox__label {
    width: 50px;
    margin-bottom: 10px;
    margin-right: 5px;
    color: #666666;
    @include add-size($font_size_16);
  }
  .btn {
    margin-top: 30px;
    text-align: center;
    button:nth-child(1) {
      width: 180px;
      background: #ffffff;
      border: 1px solid #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #3388ff;
      margin-right: 20px;
    }
    button:nth-child(2) {
      width: 180px;
      //background: #ffffff;
      border: 1px solid #3388ff;
      background: #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #fff;
    }
  }
}
.systemsetting /deep/.el-dialog {
  border-radius: 10px;
  .el-dialog__header {
    border-radius: 10px 10px 0px 0px;
    background: #3388ff;
    margin-bottom: 20px;
    .header-title {
      .title-name {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 4px solid #ffffff;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 4px;
        margin-bottom: 6px;
      }
      .title-age {
        vertical-align: top;
        display: inline-block;
        @include add-size($font_size_16);
        color: #ffffff;
      }
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }
  }
  .btn {
    margin-top: 52px;
    text-align: center;
    button:nth-child(1) {
      width: 180px;
      background: #ffffff;
      border: 1px solid #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #3388ff;
      margin-right: 20px;
    }
    button:nth-child(2) {
      width: 180px;
      //background: #ffffff;
      border: 1px solid #3388ff;
      background: #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #fff;
    }
  }
}
.content /deep/.el-table th {
  @include add-size4($font_size_18);
  vertical-align: middle;
}
.content /deep/.el-table__row {
  @include add-size($font_size_16);
  vertical-align: middle;
}
.el-table::before {

}
.el-table--border::after {

}
.el-table--border {

}
.content /deep/ .el-link {
  color: #3978E7;
  font-weight: 400;
}
.content {
  ::-webkit-scrollbar {
    width: 8px!important;
    height: 4px;

  }
  ::-webkit-scrollbar-thumb {
    //width: 20px;
    //height: 4px;
    background-color: #1890ff;
    border-radius: 10px;
  }
}
::-webkit-scrollbar {
  width: 8px!important;
  height: 8px;
  background-color: #1890ff;
  border-radius: 10px;

}
</style>


import axios from "./Interceptor.js"
import qs from "qs"
let address = window.global_config.BASE_URL
let api = {
    treetopicData: (id) => {//树形结构
        return axios({
            method: "get",
            url: `${address}TopicInfo/GetTopicInfoTreeList?Id=${id}`,
        })
    },
    login: (info) => {//登录接口
        return axios({
            method: "post",
            url: `${address}Account/Login`,
            data: qs.stringify(info)
        })
    },
    ProjectOverviewData: (id) => {//统计页面项目概览数据
        return axios({
            method: "get",
            url: `${address}TopicInfoStatistics/GetTopicInfoStatistics?UserId=${id}`,
        })
    },
    CompletionData: (id) => {//统计页面阶段统计数据
        return axios({
            method: "get",
            url: `${address}TopicInfoStatistics/GetTopicFlowNum?UserId=${id}`,
        })
    },
    StopicData: (info) => {//统计查询项目列表数据
        return axios({
            method: "post",
            url: `${address}TopicInfoStatistics/GetPaged`,
            data: qs.stringify(info)
        })
    },
    sendoutmsgData: (info) => {//查看批量发送消息通知数据
        return axios({
            method: "post",
            url: `${address}TopicMessage/GetPagedSend`,
            data: qs.stringify(info)
        })
    },
    deleteprojectData: (info) => {//删除项目
        return axios({
            method: "post",
            url: `${address}TopicInfo/Delete`,
            data: info
        })
    },
    ProjectTypeData: () => {//项目,课题,子课题.类型数据
        return axios({
            method: "get",
            url: `${address}TopicType/GetAll`,
        })
    },
    singletopicData: (id) => {//单个课题
        return axios({
            method: "get",
            url: `${address}TopicInfo/Get?Id=${id}`,
        })
    },
    ProjectModeData: (type) => {//项目,课题,子课题.方式数据
        return axios({
            method: "get",
            url: `${address}BaseData/GetBaseDataByType?type=${type}`,
        })
    },
    allroleData: () => {///获取当前登录用户的组织下所有角色身份
        return axios({
            method: "get",
            url: `${address}Role/GetAll`
        })
    },
    allpersonnelData: (id) => {//获取当前登录用户的组织下所有人员
        return axios({
            method: "get",
            url: `${address}Account/GetHospitalUser?userId=${id}`,
        })
    },
    addprojectfileData: (info) => {//创建项目,课题,子课题.
        return axios({
            method: "post",
            url: `${address}TopicInfo/Insert`,
            data: info,
        })
    },
    projectallData1: (info) => {//全部项目数据
        return axios({
            method: "post",
            url: `${address}TopicInfo/GetPagedChild`,
            data: qs.stringify(info)
        })
    },
    projectallData: (info) => {//全部项目数据
        return axios({
            method: "post",
            url: `${address}TopicInfo/GetPagedProjectChild`,
            data: qs.stringify(info)
        })
    },
    topicallData: (id) => {//查询课题列表无分页
        return axios({
            method: "get",
            url: `${address}TopicInfo/GetChildAll?Id=${id}`,
        })
    },
    hospitalData(key) {//所有医院数据
        return axios({
            method: "post",
            url: `${address}Hospital/GetAll`,
            data: key
        })
    },
    hospitalIdsData: (hospitalId) => {//通过医院ID查询用户ID
        return axios({
            method: "get",
            url: `${address}Account/GetHospitalUsers?hospitalId=${hospitalId}`,
        })
    },
    StepsData: (id) => {//步骤条数据
        return axios({
            method: "get",
            url: `${address}TopicFlow/GetAll?TopicId=${id}`
        })
    },
    addstageData: (info) => {//新增阶段
        return axios({
            method: "post",
            url: `${address}TopicFlow/Insert`,
            data: qs.stringify(info)
        })
    },
    ModificationphaseData: (info) => {//修改阶段
        return axios({
            method: "post",
            url: `${address}TopicFlow/Update`,
            data: qs.stringify(info)
        })
    },
    deletestageData: (id) => {//删除阶段
        return axios({
            method: "post",
            url: `${address}TopicFlow/Delete`,
            data: id
        })
    },
    catalogData: (id) => {//阶段所属目录的全部信息
        return axios({
            method: "get",
            url: `${address}TopicFlowFolder/GetAll?TopicFlowId=${id}`,
        })
    },
    addcatalogData: (info) => {//新增目录
        return axios({
            method: "post",
            url: `${address}TopicFlowFolder/Insert`,
            data: qs.stringify(info)
        })
    },
    modifycatalogData: (info) => {//修改目录和计划
        return axios({
            method: "post",
            url: `${address}TopicFlowFolder/Update`,
            data: qs.stringify(info)
        })
    },
    deletecatalogData: (id) => {//删除目录
        return axios({
            method: "post",
            url: `${address}TopicFlowFolder/Delete`,
            data: qs.stringify(id)
        })
    },
    catalogUploadData: (info) => {//上传目录的资料
        return axios({
            method: "post",
            url: `${address}TopicFlowFolder/UploadFile`,
            data: info
        })
    },
    modificationData: (info) => {//目录和计划修改文件说明
        return axios({
            method: "post",
            url: `${address}TopicData/Update`,
            data: qs.stringify(info),
        })
    },
    DeletecatalogfileData: (info) => {//目录和计划文件的删除
        return axios({
            method: "post",
            url: `${address}TopicData/Delete`,
            data: qs.stringify(info),
        })
    },
    filepermissionsData: (id) => {//查看文件下载权限
        return axios({
            method: "get",
            url: `${address}TopicData/DownLoadDataFileCheck?Id=${id}`,
        })
    },
    filedownloadData: (id) => {//目录和计划文件下载
        return axios({
            method: "post",
            url: `${address}TopicData/DownloadDataFile`,
            data: qs.stringify(id),
            responseType: 'blob',
        })
    },
    allplanData: (id) => {//阶段所有计划的全部信息
        return axios({
            method: "get",
            url: `${address}TopicFlowPlan/GetAll?TopicFlowId=${id}`,
        })
    },
    establishplanData: (info) => {//新增阶段创建计划
        return axios({
            method: "post",
            url: `${address}TopicFlowPlan/Insert`,
            data: qs.stringify(info)
        })
    },
    reviseplanData: (info) => {//修改阶段创建计划
        return axios({
            method: "post",
            url: `${address}TopicFlowPlan/Update`,
            data: qs.stringify(info)
        })
    },
    deleteplanData: (id) => {//删除阶段计划
        return axios({
            method: "post",
            url: `${address}TopicFlowPlan/Delete`,
            data: qs.stringify(id)
        })
    },
    planUploadData: (info) => {//上传计划的文件
        return axios({
            method: "post",
            url: `${address}TopicFlowPlan/UploadFile`,
            data: info
        })
    },
    CompletedData: (info) => {//修改计划为已完成或者未完成
        return axios({
            method: "post",
            url: `${address}TopicFlowPlan/PlanPerform`,
            data: info
        })
    },
    SubmitforreviewData: (id) => {//阶段提交审核
        return axios({
            method: "post",
            url: `${address}TopicFlow/SubmitCheck`,
            data: qs.stringify(id)
        })
    },
    addUserData: (info) => {//新增成员
        return axios({
            method: "post",
            url: `${address}Account/Insert`,
            data: info
        })
    },
    titleData: (type) => {//所有人员
        // return $word.get(mUrl + 'BaseData/GetBaseDataByType', data)
        return axios({
            method: "get",
            url: `${address}BaseData/GetBaseDataByType?type=${type}`,
        })
    },
    ReviewrejectedData: (info) => {//阶段下的审核通过或驳回
        return axios({
            method: "post",
            url: `${address}TopicFlow/FlowCheck`,
            data: info
        })
    },
    StagestatusData: (id) => {//查询当前阶段状态
        return axios({
            method: "post",
            url: `${address}TopicFlow/GetFlowState`,
            data: qs.stringify(id)
        })
    },
    // filemodifyData: (info) => {//阶段下的目录文件驳回和审核通过
    //     return axios({
    //         method: "post",
    //         url: `${address}TopicFlowFolder/DataCheck`,
    //         data: info,
    //     })
    // },
    filemodifyData: (info) => {//阶段下的目录文件驳回和审核通过
        return axios({
            method: "post",
            url: `${address}TopicFlowFolder/DataCheckAll`,
            data: info,
        })
    },
    // PlanapprovedData: (info) => {//阶段下的计划文件驳回和审核通过
    //     return axios({
    //         method: "post",
    //         url: `${address}TopicFlowPlan/DataCheck`,
    //         data: info,
    //     })
    // },
    PlanapprovedData: (info) => {//阶段下的计划文件驳回和审核通过
        return axios({
            method: "post",
            url: `${address}TopicFlowPlan/DataCheckAll`,
            data: info,
        })
    },
    lockingData: (info) => {//锁定课题
        return axios({
            method: "post",
            url: `${address}TopicInfo/TopicInfoLock`,
            data: qs.stringify(info),
        })
    },
    StagesData: (info) => {//查询操作日志列表分页
        return axios({
            method: "post",
            url: `${address}TopicOperationLog/GetPaged`,
            data: qs.stringify(info)
        })
    },
    jurisdictionData: (TopicId) => {//TopicId可以为0，为0时查出所有用户,课题下的人员
        return axios({
            method: "get",
            url: `${address}TopicUser/GetAll?TopicId=${TopicId}`,
        })
    },
    DisplayReportData: (TopicId) => {//显示报表
        return axios({
            method: "get",
            url: `${address}TopicFinanc/GetTopicFinancAccountStatistics?topicId=${TopicId}`,
        })
    },
    clickVoucherlistData: (info) => {//查询数值的凭证列表
        return axios({
            method: "post",
            url: `${address}TopicFinanc/GetSelectFinancAccountPaged`,
            data: info
        })
    },
    clickVoucherFileList: (info) => {//查询数值的凭证列表
        return axios({
            method: "post",
            url: `${address}TopicFinanc/GetSelectFinancFilePaged`,
            data: info
        })
    },
    UpdatebudgetData: (info) => {//更新预算的值
        return axios({
            method: "post",
            url: `${address}TopicAccount/Updates`,
            data: info
        })
    },
    uptopData: (info) => {//修改项目
        return axios({
            method: "post",
            url: `${address}TopicInfo/Update`,
            data: info
        })
    },
    getDepartmentInfoData: (info) => {//
        return axios({
            method: "post",
            url: `${address}Hospital/GetAllDepartment`,
            data: info
        })
    },
    adduserData: (info) => {//添加人员到项目里面
        return axios({
            method: "post",
            url: `${address}TopicUser/Insert`,
            data: qs.stringify(info)
        })
    },
    personnelupData: (info) => {//项目人员的编辑
        return axios({
            method: "post",
            url: `${address}TopicUser/Update`,
            data: qs.stringify(info)
        })
    },
    deleteuserData: (id) => {//删除项目人员
        return axios({
            method: "post",
            url: `${address}TopicUser/Delete`,
            data: qs.stringify(id)
        })
    },
    tableTitleData: (id) => {//大表
        return axios({
            method: "get",
            url: `${address}TopicFinanc/GetTopicFinancAccountStatisticsList?topicId=${id}`,
        })
    },
    conversionData:(info)=>{//阶段位置转换
        return axios({
            method:"get",
            url:`${address}TopicFlow/OrderExchange`,
            params:info
        })
    },
    UserIdsData: (UserIds) => {//添加角色
        return axios({
            method: "post",
            url: `${address}TopicUser/Inserts`,
            data: UserIds
        })
    },
    ProjectpersonnelData: (info) => {//项目人员，根据医院id和项目id
        return axios({
            method: "get",
            url: `${address}TopicUser/GetUser`,
            params:info
        })
    },
    TopicUserData: (TopicId) => {//项目人员，项目id
        return axios({
            method: "get",
            url: `${address}TopicUser/GetUserRole?TopicId=${TopicId}`,
        })
    },
    
}
export default api

import axios from "./Interceptor.js"
let mUrl =window.global_config.BASE_URL

// 获取问卷列表
export const getCrfPaged = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Crf/GetPaged',
        data,
    })
}

// 获取项目列表
export const getProject = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Project/GetPaged',
        data,
    })
}

// 新建项目信息
export const addProject = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Project/SaveProject',
        data,
    })
}

// 新建项目单位
export const addProjectCenter = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Project/SaveProjectCenter',
        data,
    })
}

// 新建项目成员
export const addProjectUser = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Project/SaveProjectUser',
        data,
    })
}

//接口
//新增项目成果
export const addProjectResults = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'project/InsertProjectResults',
        data
    })
}

//删除项目成果
export const delProjectResults = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'project/DeleteProjectResults',
        params: data
    })
}

//修改项目成果
export const editProjectResults = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'project/UpdateProjectResults',
        data,
    })
}
//上传项目文件
export const uploadProjectFile = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'project/UploadFile',
        data,
    })
}

//添加项目文件夹
export const addprojectDirectory = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'project/InsertDirectory',
        data,
    })
}

//修改项目文件夹
export const editProjectDirectory = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'project/UpdateDirectory',
        data,
    })
}

//删除文件夹
export const delProjectDirectory = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'project/DeleteDirectory',
        params: data
    })
}

//获取文件夹文件
export const getProjectDirectory = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'project/GetDirectoryFilePaged',
        data,
    })
}

//根据项目id获取项目文件夹
export const getProjectGetDirectoryList = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'project/GetDirectoryList',
        params: data
    })
}
//根据文件id删除文件
export const delProjectFile = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'project/DeleteProjectFile',
        params: data
    })
}

//添加项目成员
export const addInsertProjectUser = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'account/InsertProjectUser',
        data,
    })
}


//根据项目成员id删除项目成员
export const delProjectGetDirectoryList = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'project/DeleteProjectUser',
        params: data
    })
}
//根据项目Id获取项目所有成员
export const getAllProjectUser = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'project/GetAllProjectUser',
        params: data
    })
}

//添加项目信息（新建课题）
export const addprojectTitle = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Project/Insert',
        data,
    })
}
// 新建项目文件夹
export const addArchiveDirectory = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Project/SaveArchiveDirectory',
        data,
    })
}

// 成员维护新建成员
export const addUser = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Account/Insert',
        data,
    })
}

// 成员维护编辑成员
export const editUser = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Account/Update',
        data,
    })
}

// 成员维护删除成员
export const delUser = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Account/Delete',
        data,
    })
}

// 成员维护获取成员信息
export const getUserInfo = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'Account/Get',
        params: data
    })
}




// 根据ID查询项目信息
export const projectInfo = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'Project/GetPaged',
        data,
    })

}
// 获取项目详情
export const projectDetailsInfo = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'project/Get',
        params: data
    })

}


// 根据项目ID查询项目下单位信息
export const getProjectCenterList = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'Project/GetProjectCenter',
        params: data
    })
}

// 根据项目ID查询项目下成员信息
export const getProjectUserList = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'Project/GetAllProjectUser',
        params: data
    })
}

// 根据项目ID查询项目下文件夹信息
export const getProjectFolderList = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'Project/GetArchiveDirectory',
        params: data
    })
}

// 编辑项目提交
export const editProject = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Project/Update',
        data,
    })
}

// 删除单个项目
export const delProject = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Project/Delete',
        data,
    })
}

// 新建文件夹
export const addFolder = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Project/InsertDirectory',
        data,
    })
}

// 获取用户列表
export const getUser = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Account/GetPaged',
        data,
    })
}

// 获取默认角色信息
export const getUserRolesInfo = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'Project/GetProjectDefaultRoles',
        data,
    })
}

// 获取用户信息
export const catchUserInfo = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Account/Login',
        data,
    })
}

// 获取菜单列表
export const catchMenuList = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'Account/GetMenus',
        data,
    })
}


// 规则设置新增随访规则
export const addFollowRule = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'FollowUp/Save',
        data,
    })
}
// 规则设置获取规则列表
export const getFollowRuleList = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'FollowUp/GetBySchemePaged',
        data,
    })
}

// 规则设置删除随访规则
export const delFollowRule = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'FollowUp/DeleteScheme',
        data,
    })
}

// 规则设置编辑规则
export const editFollowRule = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'FollowUp/Update',
        data,
    })
}
// 规则设置获取规则信息
export const getRoleInfo = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'FollowUp/GetBySchemeId',
        params: data
    })
}


//保存考核指标
export const SaveAssessment = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Project/SaveProjectAssessment',
        data,
    })
}

//保存项目经费预算
export const SaveBudget = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Project/SaveProjectBudget',
        data,
    })
}



// 获取系统资源菜单
export const getRoleResources = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'Permission/GetResources',
        params: data
    })
}


// 保存编辑角色权限
export const SaveRolePermission = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Permission/SaveRolePermission',
        data,
    })
}

// 新增角色权限
export const addRolePermission = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Permission/InsertRolePermissions',
        data,
    })
}

// 获取角色下拉列表
export const GetRolesList = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'Permission/GetRoles',
        data,
    })
}

// 获取角色下拉列表
export const GetProjectRoles = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'Permission/GetProjectRoles',
        data,
    })
}

// 新建权限页面新建角色
export const GetRolePermissions = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'Permission/GetRolePermissions',
        params: data,
    })
}

// 删除角色权限
export const delRolePermissions = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'Permission/RoleDelete',
        params: data,
    })
}

// 获取医院列表
export const getHospitalList = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Hospital/GetAll',
        data: data,
    })
}

// 提交新建项目信息
export const addProjectInfo = (data) => {
    return axios.get(mUrl + 'Project/Save', data)
}

// 根据医院ID获取科室信息
export const getDepartmentInfo = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Hospital/GetAllDepartment',
        data: data,
    })
}

export const getTitleInfo = (data) => {// 获取项目详情
    return axios({
        method: "get",
        url: mUrl + 'BaseData/GetBaseDataByType',
        params: data,
    })
}
// 获取成员信息
export const getUsersInfo = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'Account/GetProjectUser',
        params: data,
    })
}
// 通过医院ID查询该项下人员
export const getHospitalAdminList = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'Account/GetHospitalAdmin',
        params: data,
    })
}
// 返回采购组织列表
export const getPurchasePagedList = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Purchase/GetPaged',
        data: data,
    })
}
// 查看单个采购组织对象
export const getPurchaseInfo = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'Purchase/Get',
        params: data,
    })
}
// 新增采购组织
export const insertPurchase = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Purchase/Insert',
        data: data,
    })
}

// 修改采购组织
export const updatePurchase = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Purchase/Update',
        data: data,
    })
}

// 删除采购组织
export const deletePurchase = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Purchase/Delete',
        data: data,
    })
}
// 添加医院
export const insertHospitalInfo = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Hospital/Insert',
        data: data,
    })
}

// 修改医院
export const updateHospitalInfo = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Hospital/Update',
        data: data,
    })
}

// 删除医院
export const delHospitalInfo = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'Hospital/Delete',
        data: data,
    })
}
//下载单位模板
export const downLoadTemplate = () => {
    return axios({
        method: "get",
        url: mUrl + 'Hospital/DownLoadTemplate',
        responseType:'blob',
    })
}

<template>
  <div>
    <el-button type="primary" icon="el-icon-plus" @click="addmember">新增成员</el-button>
    <div class="dialog_add">
      <el-dialog
        :visible.sync="dialogVisible"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">新增成员</span>
        </div>
        <div class="body">
          <div class="body-left">
            <el-form label-width="150px" :model="addForm" :rules="rules" ref="addForm">
              <el-form-item label="登录名:" required>
                <el-input v-model="LoginName" placeholder="请输入" required></el-input>
              </el-form-item>
              <el-form-item label="密码:" required>
                <el-input v-model="Password" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="姓名:" required>
                <el-input v-model="UserName" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="手机号:" prop="PhoneNumber">
                <el-input v-model="addForm.PhoneNumber" placeholder="请输入" style="width: 150px"></el-input>
                <span style="color: #F56C6C">推荐填写,该项用于接收各类通知</span>
              </el-form-item>
              <el-form-item label="身份证:" prop="IdentityCard">
                <el-input v-model="addForm.IdentityCard" placeholder="请输入" @blur="IdBlur"></el-input>
              </el-form-item>
              <el-form-item label="性别:">
                <el-radio-group v-model="Sex">
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="0">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
          <div class="body-right">
            <el-form label-width="150px" :model="addForm2" :rules="rules2">
              <el-form-item label="微信:">
                <el-input v-model="WX" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="邮箱:" prop="EMail">
                <el-input v-model="addForm2.EMail" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="职称:">
                <el-select  placeholder="请选择" v-model="Title">
                  <el-option :label="item.Name" :value="item.Name" v-for="(item,index) in TitleArray" :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="医院:" required>
                <el-autocomplete
                  class="inline-input"
                  v-model="HospitalName"
                  value-key="HospitalName"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="科室:" required>
                <el-select v-model="DepartmentId" placeholder="请选择" filterable>
                  <el-option
                    :label="item.DeptName"
                    :value="item.Id"
                    v-for="(item, index) in Department"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="角色:">
                <el-select v-model="RoleId" placeholder="请选择">
                  <el-option
                    :label="item.RoleName"
                    :value="item.Id"
                    v-for="(item, index) in option"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="btn">
          <button  @click="dialogVisible = false">取消</button>
          <el-button @click="OKmember('addForm')" :loading="loading">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from "@/api/url";
import verification from "@/utils/verification";

export default {
name: "AddUser",
  data() {
    return {
      loading: false,
      addForm2: {
        EMail: ""
      },
      addForm: {
        PhoneNumber:"",//手机号
        IdentityCard:"",//身份证
      },
      rules2: {
        EMail: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' },
          // { required: false,validator: verification.validateEmail, trigger: 'blur' }
          ],
        
      },
      rules: {
        PhoneNumber: [
          { required: false, validator: verification.validatePhone, trigger: 'blur' }],
        IdentityCard: [{ validator: verification.validateIdCard, trigger: 'blur' }]
      },
      tableData:[],
      UserId:"",
      Id:"",
      HospitalName:"",//医院名字显示
      HospitalId:"",//医院id
      Title:"",//职称
      DepartmentId:"",//科室
      RoleId:"",//角色
      LoginName:"",//登录名
      Password:"",//密码
      UserName:"",//姓名
      PhoneNumber:"",//手机号
      IdentityCard:"",//身份证
      Sex:1,//性别
      WX:"",//微信
      QQ:"",//qq
      EMail:"",//邮箱
      option:[],//角色数组
      TitleArray:[],//职称数组
      Department:[],//科室数组
      handleEditDialog: false,//打开编辑成员弹框
      dialogVisible:false,//打开新增成员弹框
      handleDelDialog:false,//打开删除成员弹框
    }
  },
  methods: {
    addmember(){//打开新增成员弹框
      this.dialogVisible=true
      this.projectId = ""
      this.LoginName = ""
      this.Password = ""
      this.UserName = ""
      this.addForm.PhoneNumber =""
      this.addForm.IdentityCard = ""
      this.NickName =""
      this.Sex = 1
      this.WX = ""
      this.QQ = ""
      this.addForm2.EMail = ""
      this.Title = ""
      this.HospitalId = ""
      this.DepartmentId =""
      this.RoleId = ""
      this.HospitalName = ""
      this.titlelist()
      this.allrolelist()
    },
    titlelist(){//职称数组
      let parame="JobTitle"
      api.titleData(parame).then(res=>{
        if(res.data.Status==1){
          this.TitleArray=res.data.Entity
        }else{
          this.$message.error(res.data.Message)
        }
      })
    },
    querySearch(queryString, cb){//查询出医院
      // console.log(queryString, cb);
      let parame={
        key:queryString
      }
      api.hospitalData(parame).then(res=>{
        // console.log(res);
        if(res.data.Status==1){
          cb(res.data.Entity)
        }else{
          this.$message.error(res.data.Message)
        }
      })
    },
    handleSelect(item){//根据医院id查出科室
      this.HospitalId=item.Id
      let parame={
        HospitalId:item.Id
      }
      api.getDepartmentInfoData(parame).then(res=>{
        if(res.data.Status==1){
          this.Department=res.data.Entity
        }else{
          this.$message.error(res.data.Message)
        }
        // console.log(res,"科室");
      })
    },
    allrolelist(){//角色
      api.allroleData().then(res=>{
        if(res.data.Status==1){
          let arr = [];
          res.data.Entity.forEach((el) => {
            // console.log(el);
            if (el.SysScope == 1) {
              arr.push(el);
            }
          });
          this.option= arr
        }else{
          this.$message.error(res.data.Message)
        }
        // console.log(res,"成员");
      })
    },
    IdBlur() {
      const regId = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[X])$)$/;
      if (!regId.test(this.addForm.IdentityCard)) {
        // console.log('身份证不正确')
      } else {
        if (this.addForm.IdentityCard.substr(16, 1)% 2 == 1){
          this.Sex = 1
        } else {
          this.Sex = 0
        }
      }
    },
    addUserInfo(){
      let parame={
        projectId:"",
        LoginName:this.LoginName,
        // LoginName:"",
        Password:this.Password,
        UserName:this.UserName,
        PhoneNumber:this.addForm.PhoneNumber,
        IdentityCard:this.addForm.IdentityCard,
        NickName:this.NickName,
        Sex:this.Sex,
        WX:this.WX,
        QQ:"",
        EMail:this.addForm2.EMail,
        Title:this.Title,
        HospitalId:this.HospitalId,
        DepartmentId:this.DepartmentId,
        RoleId:this.RoleId,
      }
      api.addUserData(parame).then(res=>{
        // console.log(res);
        if(res.data.Status==1){
          // console.log(res,'创建的人员')
          this.$message.success(res.data.Message)
          if (this.RoleId) {
            let obj={
              TopicId:0,
              RoleId:this.RoleId,
              UserId:res.data.NewID,
            }
            api.adduserData(obj).then(res=>{
              if (res.data.Status==1) {
                    console.log(res.data.Message)
              } else {
                this.$message.error(res.data.Message)
              }
            })
          }
          this.dialogVisible=false
          this.loading = false
          this.$emit('user-add', true )  //刷新列表
        }else{
          this.$message.error(res.data.Message)
          this.loading = false
        }
      })
    },
    OKmember(formName){//确定新增成员
      this.loading = true
      if (this.DepartmentId == "") {
        this.$message.error('请选择科室')
        this.loading = false
      }
      if (this.Password == "") {
        this.$message.error("请输入6-20位的密码")
        this.loading = false
        return false
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log('通过')
          this.addUserInfo()
        } else {
          this.$message.error('请填写正确信息')
          this.loading = false
          return false;
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/mixin.scss";
.dialog_add {
/deep/ .el-dialog {
  width: 1200px;
  border-radius: 10px;
.el-dialog__header {
  border-radius: 10px 10px 0px 0px;
  background: #3388ff;
.header-title {
.title-name {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 4px solid #ffffff;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 4px;
  margin-bottom: 5px;
}
.title-age {
  vertical-align: top;
  display: inline-block;
@include add-size($font_size_16);
  color: #ffffff;
}
}
.el-dialog__headerbtn .el-dialog__close {
  color: #fff;
}
}
.body {
  height: 430px;
.body-left {
  float: left;
.el-form {
.el-form-item {
.el-form-item__label {
@include add-size($font_size_16);
  color: #666666;
}
.el-input {
@include add-size($font_size_16);
  width: 390px;
}
}
}
}
.body-right {
  float: left;
.el-form {
.el-form-item {
.el-form-item__label {
@include add-size($font_size_16);
  color: #666666;
}
.el-input {
@include add-size($font_size_16);
  width: 390px;
}
.el-icon-circle-plus-outline {
  width: 30px;
  display: inline-block;
  font-size: 30px;
  vertical-align: middle;
}
.pspan {
  display: inline-block;
  width: 360px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;

.spanname {
@include add-size($font_size_16);
  margin-left: 6px;
}
}
.block {
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 390px;
}
}
.el-radio__label{
@include add-size($font_size_16);
}
}
}
}
}
.btn {
  margin-bottom: 14px;
  text-align: center;
button:nth-child(1) {
  width: 180px;
  background: #ffffff;
  border: 1px solid #3388ff;
  height: 50px;
  border-radius: 10px;
  font-size: 24px;
  color: #3388ff;
  margin-right: 20px;
}
button:nth-child(2) {
  width: 180px;
  border: 1px solid #3388ff;
  background: #3388ff;
  height: 50px;
  border-radius: 10px;
  font-size: 24px;
  color: #fff;
}
}
}
}
</style>